
.card {
	overflow: hidden;
}



/* Brightness-zoom Container */
.card img {
  transition: transform 1s, filter 0.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(90%);
}

/* The Transformation */
.card:hover img {
  filter: brightness(70%);
  transform: scale(1.15);
} 


.card-footer {
  padding-top: 0.75rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}  