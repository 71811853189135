// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		nav:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		0.375em,
		element-height:		2.75em,
		element-margin:		2em,
		sidebar-width:		26em,
		sidebar-width-alt:	24em,
		gutter:				3em
	);

// Font.
	$font: (
		family:				('Roboto', 'Noto Sans'),
		family-heading:		('Roboto', 'Noto Sans'),
		family-fixed:		('Roboto', 'Noto Sans'),
		weight:				300,
		weight-bold:		500,
		weight-heading:		600,
		weight-heading-alt:	300,
		kerning-heading:	0.075em
	) ;

// Palette.
	$palette: (
		bg:					#ffffff,
		bg-alt:				#f5f6f7,
		fg-alt:				#7f888f,
		fg:					#3d4449,
		fg-bold:			red,
		fg-light:			#9fa3a6,
		border:				rgba(210,215,217,0.75),
		border-bg:			transparentize(#e6ebed, 0.75),
		accent:				#f56a6a,
		success:			#62c462,
		warning:			#f89406,
		danger:				#ee5f5b,
		info:				#52adc8,
	);

$code-color: 			  #1dbd45!important;

/*ntt colors*/
$brand-primary:           #0072bc;
$brand-secondary:         #455565;
$brand-white:             #ffffff;
$brand-black:             #000000;
$brand-primary-dark:      #001973;

$brand-info:              #0fc8f2;
$brand-success:           #1dbd45;
$brand-warning:           #ffbd53;
$brand-danger:            #ef4023;

$gray-base:               #050505;
$gray-darkest:            #1e2022;
$gray-middle:             #282a2b;
$gray-darker:             #313639;
$gray-dark:               #444c55;
$gray-blue:               #455565;
$gray:                    #606f7d;
$gray-light:              #b3b7c3;
$gray-lighter:            #e0e3ee;
$gray-lightest:           #eff1F6;


// Dark theme:
$brand-primary:           #0072bc;
$brand-secondary:         #455565;
$brand-white:             #ffffff;
$brand-black:             #000000;
$brand-primary-dark:      #001973;

$brand-info:              #0fc8f2;
$brand-success:           #1dbd45;
$brand-warning:           #ffbd53;
$brand-danger:            #ef4023;

$gray-base:               #050505;
$gray-darkest:            #1e2022;
$gray-middle:             #282a2b;
$gray-darker:             #313639;
$gray-dark:               #444c55;
$gray-blue:               #455565;
$gray:                    #606f7d;
$gray-light:              #86919f;
$gray-lighter:            #b3b7c3;
$gray-lightest:           #e0e3ee;

// Complementary colours also have variables, but are seldom used.
$brand-complementary-1:   #753bbd;
$brand-complementary-2:   #2cd5b6;
$brand-complementary-3:   #d92373;

$border-primary:			 green !important; 


/*ntt colors*/
.brand-primary { background-color:      #0072bc; }
.brand-secondary { background-color:    #455565; }
.brand-white { background-color:        #ffffff; }
.brand-black { background-color:        #000000; }
.brand-primary-dark { background-color: #001973; }

.brand-info { background-color:         #0fc8f2; }
.brand-success { background-color:      #1dbd45; }
.brand-warning { background-color:      #ffbd53; } 
.brand-danger { background-color:       #ef4023; }

.gray-base { background-color:          #050505; }
.gray-darkest { background-color:       #1e2022; }
.gray-middle { background-color:        #282a2b; }
.gray-darker { background-color:        #313639; }
.gray-dark { background-color:          #444c55; }
.gray-blue { background-color:          #455565; }
.gray { background-color:               #606f7d; }
.gray-light { background-color:         #b3b7c3; }
.gray-lighter { background-color:       #e0e3ee; }
.gray-lightest { background-color:      #eff1F6; }

// Complementary colours also have variables, but are seldom used.
.brand-complementary-1 { background-color:   #753bbd; }
.brand-complementary-2 { background-color:   #2cd5b6; }
.brand-complementary-3 { background-color:   #d92373; }
.brand-complementary-5 { background-color:   #3eb5be; }


/*ntt colors*/
.text-brand-primary { color:      	#0072bc; }
.text-brand-secondary { color:    	#455565; }
.text-brand-white { color:        	#ffffff; }
.text-brand-black { color:        	#000000; }
.text-brand-primary-dark { color: 	#001973; }

.text-brand-info { color:         	#0fc8f2; }
.text-brand-success { color:      	#1dbd45; }
.text-brand-warning { color:      	#ffbd53; } 
.text-brand-danger { color:       	#ef4023; }

.text-gray-base { color:          	#050505; }
.text-gray-darkest { color:       	#1e2022; }
.text-gray-middle { color:        	#282a2b; }
.text-gray-darker { color:        	#313639; }
.text-gray-dark { color:          	#444c55; }
.text-gray-blue { color:          	#455565; }
.text-gray { color:               	#606f7d; }
.text-gray-light { color:         	#b3b7c3; }
.text-gray-lighter { color:       	#e0e3ee; }
.text-gray-lightest { color:      	#eff1F6; }

// Complementary colours also have variables, but are seldom used.
.text-brand-complementary-1 { color:   #753bbd; }
.text-brand-complementary-2 { color:   #2cd5b6; }
.text-brand-complementary-3 { color:   #d92373; }
.text-brand-complementary-4 { color:   #0fc8f2; }
.text-brand-complementary-5 { color:   #3eb5be; }

