

/* Typogrpahy */



body {
	color: rgba(000,000,000,0.75) !important;
}





.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}


/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
div.banner-text h1 {
	font-size: 3rem !important;
}



/* Small devices (landscape phones, 544px and up) */
@media (min-width: 320px) {  
  h1 {font-size: 1.5rem !important} /*1rem = 16px*/
}


@media (min-width: 544px) {  
  h1 {font-size:1.65rem !important} /*1rem = 16px*/
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px ) {  
  h1 {font-size:2em !important;} /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  h1 {font-size:2em !important;} /*1rem = 16px*/
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  h1 {font-size:2em !important;} /*1rem = 16px*/    
}




/*


	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 13pt;
		font-weight: _font(weight);
		line-height: 1.65;

		@include breakpoint(xlarge) {
			font-size: 11pt;
		}

		@include breakpoint(large) {
			font-size: 10pt;
		}

		@include breakpoint(xxsmall) {
			font-size: 9pt;
		}
	}

	a {
		
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.5) 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h1 {
		font-size: 4em;
		margin: 0 0 (_size(element-margin) * 0.25) 0;
		line-height: 1.3;
	}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	@include breakpoint(xlarge) {
		h1 {
			font-size: 3.5em;
		}
	}

	@include breakpoint(medium) {
		h1 {
			font-size: 3.25em;
		}
	}

	@include breakpoint(small) {
		h1 {
			font-size: 2em;
			line-height: 1.4;
		}

		h2 {
			font-size: 1.5em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 3px _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

*/
	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		border: solid 1px _palette(border);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;
		white-space: pre-wrap !important;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}
/*
	hr {
		border: 0;
		border-bottom: solid 1px _palette(border);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

*/

	