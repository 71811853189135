#api {
  h2 {
  margin-top: 1em;
  }
  h3 {
    margin-top: 1em;
  }
  h4 {
    margin-top: 1em;
  }
  h5 {
    margin-top: 1em;
  }
  div.operation {
    background: #f0f0f0;
  }
  div.operation h5 {
    padding: 0.2em;
    background: #e0e0e0;
  }
  div.operation .signature {
    font-weight: bold;
    padding: 0.2em;
  }
  div.operation .description {
    padding: 0.2em;
  }
  table th,
  table td {
    padding: 0.2em;
  }
  table.fields {
    width: 100%;
  }
  table.fields thead tr th {
    background: #c0c0c0;
  }
  table.fields tbody tr th {
    background: #e0e0e0;
    vertical-align: top;
    border-bottom: 1px solid #ababab;
    width: 25%;
  }
  table.fields tbody tr td.type {
    font-weight: bold;
  }
  table.fields tbody tr td.type pre {
    margin: 0px;
  }
  table.fields tbody tr td.description {
    border-bottom: 1px solid #ababab;
  }
  table.fields tbody tr td.description *:last-child {
    margin-bottom: 0px;
  }
  table.fields tbody tr td.none {
    border-bottom: 1px solid #ababab;
  }
 } 