.modal-backdrop {
  opacity: .85 !important;
}

code {
  background-color: black;
  scroll-behavior: none;
}

.code-box {
  background-color: black;
  padding: 2em;
  border-radius: 4px;
  border: 2px solid $brand-success;
  max-width: 800px;
}


.agentPostImage {
  width: 400px !important;
}


.panel-heading {
  background-color:transparent;
  border-radius: 8px;
  transition: all 0.5s;
  cursor: pointer;
}     

.panel-heading:hover {
    background-color:$brand-primary;
    color: white;
    text-decoration: none;
    transition: all 0.5s;
    border: solid 1px $brand-primary;
  }

 .panel-heading.on {
    background-color:$brand-primary;
    color: white;
    text-decoration: none;
    transition: all 0.5s;
    border-radius: 8px 8px 0px 0px;
    border: solid 1px $brand-primary;
  }


.panel-heading:before {
  content: "\f106";
  font-family: 'Font Awesome 5 Free';
  font-weight: 500;
  transition: all 0.5s;
  float: right;
  display: block;
}

.panel-heading.on:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.5s;
} 

h1.blueHeader, h2.blueHeader, h3.blueHeader, h4.blueHeader, h5.blueHeader {
  color: #0072bc;
  text-align: center;
}

/* Media query for medium screens and up */
@media (min-width: 768px) { 
    h1.blueHeader {
        text-align: left;  /* align left on medium and larger screens */
    }
}








