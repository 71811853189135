
@media only screen and (min-width: 601px) {
.banner {
  color: white;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;

}


.banner-text {
  z-index: 2;
  text-align: center;
  padding-top: 140px;
  padding-bottom: 40px;
  width: 100%;
  height: 440px;
  background:rgba(0,0,0,0.15);
  font-family: Roboto,arial,sans-serif;
  color: #fff;
}

.banner h1 {
  font-size: 60px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.2em;
}

.banner p {
  font-size: 18px;
  text-align: center;
  line-height: 1.5em;
}


.banner-image {
  height: 440px;
}

.banner-offset {
  margin-top: 107px;
}


}

@media only screen and (max-width: 600px) {
  .banner {
    height: 200px;
  }

  .banner-text {
    z-index: 2;
    background: rgba(0,0,0,0.25);
    font-family: Roboto,arial,sans-serif;
    color: #fff;
    padding-left:10px;
    padding-right:10px;
  }

  .banner-text p {
    text-align: center;
    margin: 0; 
  }  

.banner-headline-small {
    font-size: 5vw;
    font-weight: 700;
    text-align: center;
    min-font-size: 16px;
    max-font-size: 30px;
}


  .banner-image {
    height: 200px;
  }

  .banner-offset {
    margin-top: 0px;
  }
}


