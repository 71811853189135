/* Animation */
@-webkit-keyframes 
pulsate {  0% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 0.8;
}
 45% {
 -webkit-transform: scale(1.75);
 transform: scale(1.75);
 opacity: 0;
}
}
@keyframes 
pulsate {  0% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 0.8;
}
 45% {
 -webkit-transform: scale(1.75);
 transform: scale(1.75);
 opacity: 0;
}
}




#scrollablePostContent {
    overflow-y: visible; /* This should allow the content to be scrolled with the page */
    max-height: none;
}

#scrollablePostContent::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and newer versions of Opera */
}

#scrollablePostContent {
    -ms-overflow-style: none;  /* for Internet Explorer and Edge */
    scrollbar-width: none;  /* for Firefox */
}



/* Hotspot */

#hotspotImg {
  background-color: #ededed;
  background-size: cover;
  background-position: center center;
  position: relative;
}

#hotspotImg .img-responsive { max-width: 100%; }

#hotspotImg .hot-spot {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 5px;
  left: 5px;
  text-align: center;
  background-color: rgba(229, 0, 138, 0.6);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  transition: all .3s ease;
}

#hotspotImg .hot-spot .circle {
  display: block;
  position: absolute;
  top: 45%;
  left: 45%;
  width: 2em;
  height: 2em;
  margin: -1em auto auto -1em;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  border-radius: 50%;
  border: 4px solid rgba(15, 200, 242, 0.8);
  opacity: 0;
  -webkit-animation: pulsate 3s ease-out infinite;
  animation: pulsate 3s ease-out infinite;
}

#hotspotImg .hot-spot .tooltip {
  background-color: rgba(95, 39, 232, 0.9);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  display: none;
  font-size: 14px;
  opacity: 1.0;
  left: 0px;
  padding: 15px 5px;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 280px;
  z-index: 999;
}



#hotspotImg .hot-spot .tooltip .img-row {
  padding: 10px;
  text-align: center;
}

#hotspotImg .hot-spot .tooltip .text-row { padding: 10px; }

#hotspotImg .hot-spot .tooltip h4 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff;
}

#hotspotImg .hot-spot .tooltip p {
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 10px;
}

#hotspotImg .hot-spot .tooltip p:last-child { margin-bottom: 0; }

/* post list accordion */
.post-list-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.post-list-accordion-link {
    text-decoration: none; /* remove underline */
    cursor: pointer; /* make it look clickable */
}

.post-list-nav-title {
  color: #777;
  padding-left: 0.75em;
}
.post-list-card-header {
  border:none;
}

.post-list-card-header .post-list-header-nav-btn-link {
    display: flex;
    justify-content: space-between;
    align-items: center;  
    width: 100%;  
}

a.post-list-header-nav-btn-link {
  color: #555;
  padding: 0.75em;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-decoration: none;
}

a.post-list-header-nav-btn-link:hover {
  color: #444;
  background-color: rgba(0,0,0,0.05);
  text-decoration: none;
}

.post-list-card-body {
  padding-left:1.0em;

}

.post-list-card-body ul {
  border-left: solid 1px rgba(0,114,188,0.5);
}


a.post-list-nav-btn-link {
  color: #555;
  padding: 0.75em 0 0.75em 0.75em;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-decoration: none;
}


a.post-list-nav-btn-link:hover {
  text-decoration: none;
  background-color: rgba(0,0,0,0.05);
  font-weight: 500;
  color: #444;
  padding: 0.75em 0 0.75em 0.75em;
}

a.post-list-nav-btn-link-active {
  background-color: rgba(0,114,188,0.1);
  border: none;
  border-radius: 4px;
  color: rgba(0,114,188,1.0);
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 0.75em 0 0.75em 0.75em;
  text-decoration: none;
  transition: all 0.4s ease 0s;
  width: 100%;
}

a.post-list-nav-btn-link-active:hover {
  text-decoration: none;
  color: rgba(0,114,188,1.0);  
}

#post-nav, #toc-nav {
    position: sticky;
    top: 0;
    overflow-y: auto; 
}

.toc-title {
  color: #777;
}

.toc-link {
    display: block;
    padding: 0.75em 0 0.75em 0.75em;
    font-size: 14px;
    text-decoration: none;
    color: #555
}

.toc-link:hover {
    display: block;
    padding: 0.75em 0 0.75em 0.75em;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: #444;
}

.toc-link.active {
    font-weight: bold;
    color: rgba(0,114,188,1.0);
    text-decoration: none;
    font-weight: 500;
}

#scrollablePostContent::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and newer versions of Opera */
}

#scrollablePostContent {
    -ms-overflow-style: none;  /* for Internet Explorer and Edge */
    scrollbar-width: none;  /* for Firefox */
}
