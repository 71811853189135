.social-list-icon {
	cursor: pointer;
	transition: transform .25s ease-out, -webkit-transform .25s ease-out;
	font-size: 16px;
	font-weight: 400;
}

.social-list-icon:hover {
transform: translateY(-5px);
}



