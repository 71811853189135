



#releaseNotesTab .nav-tabs {
    border-bottom: 1px solid var(--hr-border,#e0e3ee);
    white-space: nowrap;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

#releaseNotesTab li.active > a:nth-child(1) {
    margin-bottom: -1px;
    text-align: center;
}

#releaseNotesTab .nav-tabs>li>a {
    border: none;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    display: block;
    line-height: 34px;
    margin: 0;
    min-height: 40px;
    padding: 1px 16px;
    color: #0fc8f2;
}

#releaseNotesTab .nav-tabs>li>a:hover {
    border-color: transparent transparent transparent;
    text-decoration: none;
}

#releaseNotesTab .nav-tabs>li.active>a,.nav-tabs>li.active>a:focus,.nav-tabs>li.active>a:hover {
    color: #212529;
    border: none;
    border-bottom: 4px solid var(--nav-tabs-active-link-border-color,#0fc8f2);
    font-weight: 700;
}

#releaseNotesTab .panel .nav-tabs>li.active>a,.panel .nav-tabs>li.active>a:focus,.panel .nav-tabs>li.active>a:hover {
    background-color: var(--nav-tabs-active-link-panel-bg,#eff1f6);
}

#releaseNotesTab .nav-tabs>li.disabled {
    cursor: not-allowed;
}

#releaseNotesTab .nav-tabs>li.disabled>a {
    font-style: italic;
    pointer-events: none;
}

#releaseNotesTab .nav-tabs>li.dropdown>a {
    padding-right: 38px;
}

.nav-tab.nav-justified {
    min-width: 100%;
}

#releaseNotes .nav-tabs-responsive {
    overflow-x: auto;
    overflow-y: hidden;
}

@media (max-width: 1199px) {
    #releaseNotesTab .nav-tabs-responsive .nav-tabs>li {
        float:none;
        display: inline-block;
    }
}

#releaseNotesTab .nav-tabs-responsive.scrollbars.scroll-scrollx_visible {
    padding-bottom: 16px;
}

#releaseNotesTab .nav-tabs-responsive.scrollbar-inner>.scroll-element.scroll-x {
    left: 0;
    right: 0;
}

#releaseNotesTab .tab-content {
    margin: 16px 8px 0 8px;
}

#releaseNotesTab .nav-pills>li>a {
    border-radius: 4px;
    padding: 3px 8px;
    
}

#releaseNotesTab  .nav-pills>li.dropdown>a {
    padding-right: 38px;
}

#releaseNotesTab .nav-pills>li+li {
    margin-left: 2px;
}

