@import 'components/image';

@import 'fontawesome/fontawesome';
@import 'brands.css';
@import 'regular.css';
@import "fontawesome/solid";
@import "fontawesome/light";
@import "fontawesome/brands";
@import "fontawesome/regular";
@import "fontawesome/duotone";
@import 'v4-shims.css';
@import 'libs/vars';


// Base.
@import 'base/page';
@import 'base/typography';
	
// Component.
@import 'components/button';
@import 'components/footer';
@import 'components/topNav';
@import 'components/breadcrumbs';
@import 'components/megaMenu';
@import 'components/smallMenu';
@import 'components/social-list';
@import 'components/image';
@import 'components/api';
@import 'components/tables';
@import 'components/cards';
@import 'components/demo';
@import 'components/tabs';
@import 'components/callouts';

// Bootstrap
@import 'bootstrap/bootstrap';

// Layout.
@import 'layout/header';
@import 'layout/banner';
@import 'layout/posts';


input[type="text"], textarea {
	outline: none;
	box-shadow:none !important;
	border:1px solid #ccc !important;
}

.no-scroll {
    overflow: hidden;
}

body {
	overflow-x: hidden;
}







	
