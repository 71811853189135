ul.megaMenu-panel {
  list-style: none;
  padding-top:16px;
  width: 100%;
}

.megaMenu-panel > li > div {
  position: fixed;
  display: inline;
  margin-left: 6%;
  width: 60%;
  top: 107px;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  background: #ffffff;
  border-radius: 0 0 3px 3px;
  background: none;
  height: 100vh;
  z-index: 1010;

}

.mega-menu-container {
  margin-left: 8.333333%;
  transition: visibility 1ms, opacity 4ms ease-in, overflow 1ms;
}

.mega-menu-container--visible{
  opacity: 1 !important;
  visibility: visible !important;
  overflow: visible !important;

}

.megaMenu-panel-left {
  @media (min-width: 1024px) and (max-height: 1100px) {
    padding: 20px 30px 20px 50px !important;

  }
  @media (max-width: 1550px){
    padding: 70px 60px 20px 70px !important;

  }
  padding: 80px 70px 20px 80px !important;

  background: transparentize($brand-primary, 0.10);
  height: 100vh;
}

.megaMenu-panel-right {
  background: transparentize(#ffffff, 0.10);
  height: 100vh;
}

.mega-menu-container a {
  text-decoration: none;
  font-size: 32px;
  line-height: 36px;
  width: 900;
  color: #fff;
  display: block;
  padding-bottom: 10px;

  @media (min-width: 1024px) and (max-height: 1100px){
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 1550px) {
    font-size: 20px;
    line-height: 32px;
  }

}

.mega-menu-container a:hover, .mega-menu-container a:focus {
  color: #fff;
}

.megaMenu-panel .closebtn {
  display: block;
  position: absolute;
  top: 125px;
  font-size: 32px;
  right: 15%;
  color: #000;
  cursor: pointer;

}

.megaMenu-panel .closebtn:hover {
  color: rgba(000,000,000,0.75);
}

.megaMenu-panel-left-btn-group {
  display: block;
  text-align: left;
  min-width: 315px;
  width: calc(100% - 30px);
}

a.megaMenu-panel-right {
 
}

.megaMenu-panel-right-inner  {
  margin-top: 65px;
  max-width: 420px;
  padding-left: 30px;
  text-align: left;
}

.megaMenu-panel-right-content {
  margin-top: 120px;
}

.megaMenu-panel-right-headline {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-transform: none;
  border-bottom: 1px solid #0fc8f2;
  display: inline-block;
  min-width: 315px;
}

.megaMenu-panel-right-headline2 {
  font-size: 16px;
  font-weight: 400;
}

.row-dense {
  margin-left: 0px!important;
  margin-right: 0px!important;
}

/*

.overlay {
  height: 0%;
  width: 60%;
  left: 6em;
  position: fixed;
  z-index: 2005;
  top: 107px;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}

*/

/*--------
.navBar {
  height: 107px;
  border-bottom: 10px solid $brand-primary;
}

.navBar-page-links a {
  background: linear-gradient(currentColor, currentColor) bottom / 0 .2em no-repeat;
  transition: .5s background-size;
  padding-bottom: .4em;
  border-radius: 4px;
  color: $brand-primary;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
    &::after {
      content: "+";
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      size: 17px;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;  
    }
  }

.navBar-page-links a:hover {
  background-size: 100% .1em;
  border-radius: 2px;
  color:#1eb1e7;
    &::after {
      content: "-";
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      size: 17px;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;       
    }
  }



*/
