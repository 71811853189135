@media screen and (max-width: 767px) {
    div.markdown_table + table {
        display: block; 
        width: 100%; 
        overflow-x: auto; 
        white-space: nowrap; 
    }
}


div.markdown_table + table {
	width:100%;
}
div.markdown_table + table th {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6; 
	background-color: rgba(0,0,0,.03);
	text-align: center;
	padding: 0.5rem;
	vertical-align: top;
}
div.markdown_table + table tr {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
}
div.markdown_table + table td {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
	padding: 0.5rem;
	vertical-align: top;
}

#markdown_table_users  {
	width:100%;
	
}
#markdown_table_users th {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6; 
	background-color: rgba(0,0,0,.03);
	text-align: center;
	padding: 0.5rem;
	vertical-align: top;
}
#markdown_table_users tr {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
}

#markdown_table_users td {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
	padding: 0.5rem;
	vertical-align: top;
	text-align: center;
}

#markdown_table_users .spanRow {
	background-color: rgba(0,0,0,1.0); 
	color: white;
	text-align: left;
}

#markdown_table_users .tableDivider {

 background-color: rgba(0,0,0,.03);

 }

#markdown_table_users .left {text-align:left;}
#markdown_table_users .right {text-align:right;}
#markdown_table_users .center {text-align:center;}

#markdown_table_activityLogs  {
	width:100%;
	
}
#markdown_table_activityLogs th {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6; 
	background-color: rgba(0,0,0,.03);
	text-align: center;
	padding: 0.5rem;
	vertical-align: top;
}
#markdown_table_activityLogs tr {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
}

#markdown_table_activityLogs td {
	border-style: solid; 
	border-width: 1px;
	border-color: #dee2e6;
	padding: 0.5rem;
	vertical-align: top;
	text-align: left;
}

#markdown_table_activityLogs .spanRow {
	background-color: rgba(0,0,0,1.0); 
	color: white;
	text-align: left;
}

#markdown_table_activityLogs .tableDivider {

 background-color: rgba(0,0,0,.03);

 }

#markdown_table_activityLogs .left {text-align:left;}
#markdown_table_activityLogs .right {text-align:right;}
#markdown_table_activityLogs .center {text-align:center;}



div.post-article-link + ul a{
  position: relative;
  color: rgba(000,000,000,0.95);
  font-size: 12px;
  text-decoration: none;
  background-image: linear-gradient(currentColor,currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 2px;
  word-break: none;
  transition: opacity .25s ease-out,background-size .25s ease-out;
}

div.post-article-link + ul a:hover {
  opacity: .85;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}

div.post-article-link + ul  {
  list-style: none;
  margin-left: -40px;
}


div.markdown_table + table tbody tr td a{
  position: relative;
  color: rgba(000,000,000,0.95);
  font-size: 12px;
  text-decoration: none;
  background-image: linear-gradient(currentColor,currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 2px;
  word-break: none;
  transition: opacity .25s ease-out,background-size .25s ease-out;
}

.markdown_table + table tbody tr td a:hover {
  opacity: .85;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}

.markdown_table + td  {
  list-style: none;
  margin-left: -40px;
}

.equal-width td {   width: 50%; }

@media screen and (max-width: 767px) {
    .table-responsive {
        display: block; 
        width: 100%; 
        overflow-x: auto; 
        white-space: nowrap; 
    }
}

