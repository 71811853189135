@media only screen and (min-width: 1295px) { 
  #megaMenu {
    display: inline-block;
  }
  #smallMenu {
    display: none;
  }  
}


@media only screen and (max-width: 1295px) { 
  #megaMenu {
    display: none;
  }
  #smallMenu {
    visibility: visible;
  }
}

/* hamburger */

.smallMenu-open-overlay {
  height: 100% !important;
}

#smallMenu-hamburger {
  width: 24px;
  height: 24px;
  position: relative;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#smallMenu-hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 25px;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#smallMenu-hamburger span:nth-child(1) {
  top: 0px;
}

#smallMenu-hamburger span:nth-child(2),#smallMenu-hamburger span:nth-child(3) {
  top: 9px;
  background-color: white;

}

#smallMenu-hamburger span:nth-child(4) {
  top: 18px;
}

#smallMenu-hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#smallMenu-hamburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #000;
}

#smallMenu-hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #000;
}

#smallMenu-hamburger.open span:nth-child(4) {
  top: 0px;
  width: 0%;
  left: 50%;
}


/* search */

button.smallMenu-search-submit {
  width: 90%;
  border: none;
  background-color: transparent;
  color: black;
  margin-top: 10px;
  padding: .5em;
  border-radius: 4px;
  font-weight: 900;
}


#smallMenu-toggle-btn {
  color: #000;
}

button.smallMenu-search-submit {
  &::after {
      content: "\f061";
      color: #e6442b;
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-right: 14px;
      padding-left: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
        color: black;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}


/* overlay */

#smallMenu-overlay {
  width: 100%;
  position: fixed;
  z-index: 3;
  height: 0px;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
}


div.container-fluid.smallMenu-overlay-links {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 100px;
}

.smallMenu-close-btns {
  color: white;
  font-size: 2em;
  transition: 1.5s ease-in-out, -webkit-transform 1.2s ease-in-out;
}



.smallMenu-subNav-overlay-close-angle {
  color: $brand-primary;
  float:left;
  cursor: pointer;
}
.smallMenu-subNav-overlay-close-times {
  color: $brand-primary;
  float: right;
  cursor: pointer;
}

.smallMenu-overlay-angle {
  color: $brand-primary;
  cursor: pointer;
  font-size: 18px;
}

.smallMenu-panel-btn-link {
  background-color: transparent;
  border-radius: 2px;
  border: none;
  color: white;
  font-size: 18px;
}

.smallMenu-overlay-btn-link {
  font-weight: 900;
  border: none;
  color: $brand-primary;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  margin-bottom: -10px;
  border: none;
  cursor: pointer;
}

.smallMenu-overlay-btn-link  {
  position: relative;
  width: 300px;
}
.smallMenu-overlay-btn-link:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 8px;
  left: 1em;
  background-color: $brand-primary;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.smallMenu-overlay-btn-link:hover:before {
  visibility: visible;
  width: 30px;
}


#smallMenu-subNav-overlay-1 {
  width: 0;
  position: fixed;
  z-index: 4;
  height: 875px;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
}

#smallMenu-subNav-overlay-2 {
  width: 0;
  position: fixed;
  z-index: 4;
  height: 875px;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
}

#smallMenu-subNav-overlay-3 {
  width: 0;
  position: fixed;
  z-index: 4;
  height: 875px;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
}

#smallMenu-subNav-overlay-4 {
  width: 0;
  position: fixed;
  z-index: 4;
  height: 875px;
  top: 0;
  left: 0;
  background-color: $brand-primary;
  color: white;
  overflow-x: hidden;
  transition: 0.5s;
}

/* smallMenu buttons & links */


.smallMenu-panel-btn-wt {
  display: inline-block;
  position: relative;
  padding-right: 30px;
  border-radius: 2px;
  padding: 8px 50px 8px 15px;
  font-weight: 400;
  border: 2px solid #fff;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  background-color: transparent;
  margin-bottom: 20px;
  width: 160px;
}

.smallMenu-panel-btn-wt a:hover::after {
  right: 15px;
}

.smallMenu-panel-btn-wt a:after {
    position: absolute;
    content: "\f061";
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    line-height: 0;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: currentColor;
    -webkit-transition: right .25s ease-out;
    transition: right .25s ease-out;
}

.smallMenu-panel-btn-wt a {
  color: white;
  text-decoration: none;
}

.smallMenu-panel-btn-wt a:hover {
  color: white;
  text-decoration: none;
}

.smallMenu-panel-btn-blk {
  display: inline-block;
  position: relative;
  padding-right: 30px;
  border-radius: 2px;
  padding: 8px 50px 8px 15px;
  font-weight: 400;
  border: 2px solid rgba(250,250,250,0.65);
  color: white;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  background-color: rgba(000,000,000,0.15);
  margin-top: 20px;
  width: 160px;
}

.smallMenu-panel-btn-blk:hover::after {
  right: 15px;
}

.smallMenu-panel-btn-blk:after {
    position: absolute;
    content: "\f061";
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    line-height: 0;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: currentColor;
    -webkit-transition: right .25s ease-out;
    transition: right .25s ease-out;
}

.smallMenu-panel-btn-blk a {
  color: white;
  text-decoration: none;
}

.smallMenu-panel-btn-blk a:hover {
  color: white;
  text-decoration: none;
}

.smallMenu-overlay-angle {
  color: $brand-primary;
  cursor: pointer;
  weight: 900;
  font-size: 18px;
}

.smallMenu-panel-btn-link {
  background-color: transparent;
  border-radius: 2px;
  border: none;
  padding: 10px;
  color: white;
  font-size: 18px;
}


.smallMenu-panel-btn-link a {
  text-decoration: none;
  color: white;
}

.smallMenu-panel-btn-link a:hover {
  text-decoration: none;
  color: white;
}



