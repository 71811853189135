
.click-image-zoom {
    position: relative;
    overflow: hidden;
}

.click-image-zoom img {
    transition: transform 0.5s;
    width: 100%;
}

.click-image-zoom:hover img {
    transform: scale(1.1);
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15); 
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15);
	border: solid 1px rgba(0,0,0,0.35); 	
}

.prompt-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    display: none;
    transition: opacity 0.3s;
}

div.click-image-zoom div.prompt-text {
    display: none;
}

div.click-image-zoom:hover div.prompt-text {
    display: block;
}




span.zoom-2-no-shadow > img {
	transition: transform .8s; 
	cursor: zoom-in;
}

span.zoom-2-no-shadow > img:hover {
	transform: scale(2.0);
  	z-index: 2;
  	position: relative;
  	transition: transform .8s;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important;	
}


.banner-text > h1:nth-child(1){
	text-shadow: 2px 2px 2px rgba(0,0,0,0.25);
}

.banner-text > p:nth-child(2){
	text-shadow: 2px 2px 2px rgba(0,0,0,0.25);
}

.card-img-overlay h5  {
	text-shadow: 2px 2px 2px rgba(0,0,0,0.50);
}

.card-img-overlay p {
	text-shadow: 2px 2px 2px rgba(0,0,0,0.50);
}


.postImg50 {
	width: 50%;
  	border: 1px solid rgba(0,0,0,0.1);
	box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
	transition: transform .8s;	
}

.no-shadow img {
	border:none;
	box-shadow: none;
}

#posts img {
	width: 100%;
  	border: 1px solid rgba(0,0,0,0.1);
	box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
	transition: transform .8s;
}



.zoom-post {
	cursor: zoom-in;
}

.zoom-post:hover {
	transform: scale(1.15);
 	z-index: 3 !important;
 	position: relative !important;
 	-webkit-box-shadow: 0px 10px 13px -7px rgba(0,0,0,0.35), 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
	box-shadow: 0px 10px 13px -7px rgba(0,0,0,0.35), 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
}	


@media screen and (max-width: 600px) {
	.zoom-post:hover {
	transform: none;
	cursor: auto;
	box-shadow: 0px 3px 15px rgba(0,0,0,0.2) !important;
	}	
}



.zoom-1 {
	transition: transform .8s; 
	cursor: zoom-in;
}

.zoom-1:hover {
	transform: scale(1.3);
  	z-index: 2;
  	position: relative;
  	transition: transform .8s;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important;	
}

@media screen and (max-width: 600px) {
	.zoom-1:hover {
		transform: scale(1.15);
	  	z-index: 2;
	  	position: relative;
	  	transition: transform .8s;
		-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
		box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important;
	}
}

.zoom-1-no-shadow {
	transition: transform .8s; 
	cursor: zoom-in;
}

.zoom-1-no-shadow:hover {
	transform: scale(1.3);
  	z-index: 2;
  	position: relative;
  	transition: transform .8s;	
}

@media screen and (max-width: 600px) {
	.zoom-1-no-shadow:hover {
		transform: scale(1.15);
	  	z-index: 2;
	  	position: relative;
	  	transition: transform .8s;
	}
}


.zoom-2 {
	transition: transform .8s; 
	cursor: zoom-in;
}

.zoom-2:hover {
	transform: scale(1.5);
  	z-index: 2;
  	position: relative;
  	transition: transform .8s;
	-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
	box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important;	
}

@media screen and (max-width: 600px) {
	.zoom-2:hover {
		transform: scale(1.2);
	  	z-index: 2;
	  	position: relative;
	  	transition: transform .8s;
		-webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important; 
		box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.15) !important;
	}
}

.zoom-2-no-shadow {
	transition: transform .8s; 
	cursor: zoom-in;
}

.zoom-2-no-shadow:hover {
	transform: scale(1.5);
  	z-index: 2;
  	position: relative;
  	transition: transform .8s;	
}

@media screen and (max-width: 600px) {
	.zoom-2-no-shadow:hover {
		transform: scale(1.2);
	  	z-index: 2;
	  	position: relative;
	  	transition: transform .8s;
	}
}

.zoom-to-right-no-shadow {
    transition: transform .8s;
    transform-origin: left center; /* Adjust the transform-origin */
    cursor: zoom-in;
}

.zoom-to-right-no-shadow:hover {
    transform: scale(1.5);
    z-index: 2;
    position: relative;
    transition: transform .8s;
}

@media screen and (max-width: 600px) {
    .zoom-to-right-no-shadow:hover {
        transform: scale(1.2);
        z-index: 2;
        position: relative;
        transition: transform .8s;
    }
}

.zoom-to-left-no-shadow {
    transition: transform .8s;
    transform-origin: right center; /* Adjust the transform-origin */
    cursor: zoom-in;
}

.zoom-to-left-no-shadow:hover {
    transform: scale(1.5);
    z-index: 2;
    position: relative;
    transition: transform .8s;
}

@media screen and (max-width: 600px) {
    .zoom-to-left-no-shadow:hover {
        transform: scale(1.2);
        z-index: 2;
        position: relative;
        transition: transform .8s;
    }
}



