.social-list-icon {
  display: inline;
}

#breadcrumb-social-list-row {
  background-color: rgba(0,0,0,0.05); 
  display: flex;
}

.fa-home {  
  color: rgba(000,000,000,1) !important;
  line-height: 2em;
}

.fa-home:hover {  
  color: rgba(000,000,000,0.65) !important;
}

.list-inline-item {
  display: none;
} 

li.breadcrumbs-link::before {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  color: rgba(000,000,000,1);
  margin-right: .5em;

}  

li.breadcrumbs-link::hover {
  opacity: .85;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}  


li.breadcrumbs-link a  {
  text-decoration: none;
  color: rgba(000,000,000,0.9);
  line-height: 2em;
} 

li.breadcrumbs-link  {
  position: relative;
  color: currentColor;
  font-size: 12px;
  text-decoration: none;
  background-image: linear-gradient(currentColor,currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 90% 1px;
  word-break: none;
  transition: opacity .25s ease-out,background-size .25s ease-out;
}

.breadcrumbs-link:hover {
  opacity: .85;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}


p.breadcrumbs-no-link  {
  font-size: 12px;
}

.breadcrumbs-no-link::before {
  content: "\f054" ;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400 ;
  color: rgba(000,000,000,1) ;
  margin-right: .5em;
}

p.breadcrumbs-home-link  {
  font-size: 12px;
}

p.breadcrumbs-home-link::before {
  content: "\f054" ;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400 ;
  color: rgba(000,000,000,1) ;
  margin-right: .5em;
}



.social-list-icon {
  display: inline;
}


.bread-crumb-angle-after::after {
  content: "\f054" ;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400 ;
  color: rgba(000,000,000,1) ;
  margin-right: 1em;
  margin-left:  1em;
}

p.breadcrumbs-no-click {
  font-size: 12px;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 90% 1px;
  word-break: none;
  padding-bottom: 3px;  /* Adjust this value to increase or decrease space */
}

.breadcrumbs-no-click::before {
  content: "\f054" ;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400 ;
  color: rgba(000,000,000,1) ;
  margin-right: .5em;
}



