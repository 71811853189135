$animate: all 0.3s ease-in-out;
/* Header */

#header {
  ul,li {
    list-style: none;
  }
  li {
  display: inline-block;
  }
}

#header {
  height: 80px;
}






#bigNav {
padding-left:30px;
padding-right:30px;
padding-top:14px;
  
}

#bigNav-row {

}

#bigNav-logo img{
  display: block;
  height: 34px;
}

@media only screen and (max-width: 1249px) {
  #bigNav{
    display: none;
  }
}


.topNav-pannel-btn-group {
margin-left: 20%;
text-align: left;
}


.topNav-links a{
 
  padding-top: 16px;
  padding-left: 16px;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  color:#0072bc;
  font-family: Roboto,arial,sans-serif;
  text-decoration: none;
  
}


.topNav-links a:hover {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #0072bc;
}



.topNav-open-1 {
  display: none;
}

.topNav-open-2 {
  display: none;
}

.topNav-open-3 {

  display: none;
}
.topNav-open-4 {

  display: none;
}
.smallNav-open {

  display: none;
}



.topNav-left-pannel {
 background: #0072bc;
 color: #fff;
 height: 1000px;
}

.topNav-left-pannel a {
 color: #fff;
 margin-bottom: 32px;
}



.topNav-left-pannel-inner {
  padding:40px;
}

.topNav-right-pannel {
 background: #bad2ed;
 color: #000;
 height: 1000px;
}

a.topNav-right-pannel {
 color: #000;
}

.topNav-right-pannel-inner  {
    margin-top: 65px;
    max-width: 420px;
    padding-left: 60px;
}

.topNav-right-close-btn {
  font-size:28px;
  margin-right:30%;
  margin-top: 40px;
}


.topNav-right-headline {
  font-size: 18px;
padding-bottom: 20px;
margin-bottom: 20px;
text-transform: none;
border-bottom: 1px solid #0fc8f2;
 display: inline-block;
 min-width: 315px;
}








#topNav-underline {

  .topNav-underline {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
   
    .hover {
      text-align: center;
      margin: 0 auto;
      padding: 0;
      transition: $animate;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 0px;
        height: 2px;
        margin: 10px 0 0;
        transition: $animate;
        transition-duration: 0.4s;
        opacity: 0;
        background-color: #0072bc;
      }

      &.topNav-underline-hover {
        &:before {
          left: 50%;

        }
        &:after {
          right: 50%;
        }
      }
     
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 10px;
          opacity: 1;
           
        }
        &.topNav-underline-hover,
        {
          &:before,
          &:after {
            width: 10px;
            background-color: #0072bc;
          }
        }
      }
    }
  }
}

.sideNav-btn-link a{
  display: flex;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  color:#0072bc;
  font-family: Roboto,arial,sans-serif;
  text-decoration: none;
  text-align: left;
}

.sideNav-btn-link a:hover {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #0072bc;
}
#smallNav-underline {

  .smallNav-underline {
    display: flex;
    flex-flow: row nowrap;
    align-items: left;
   
    .hover {
      margin: 0 auto;
      padding: 0;
      transition: $animate;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 0px;
        height: 2px;
        margin: 10px 0 0;
        transition: $animate;
        transition-duration: 0.4s;
        opacity: 0;
        background-color: #0072bc;
      }

      &.smallNav-underline-hover {
         &:before,
        &:after {
          left: 0;
        }
     }
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 24px;
          opacity: 1;
           
        }
        &.smallNav-underline-hover,
        {
          &:before,
          &:after {
            width: 20px;
            background-color: #0072bc;
          }
        }
      }
    }
  }
}







	