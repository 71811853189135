html {
  overflow-x: hidden;
}


 .row-overlay {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  max-width: 1920px;
  height: auto;
  opacity:100%;
  transition:opacity 500ms ease-in-out;
}

.img-comp-container {
    position: relative;
    height: auto;
    max-width: 1920px;
    width: 100%;
    left:50%;
    transform:translateX(-50%);
      height: auto;
}

.img-comp-responsive {
    height: 0;
    width: 100%;
    padding-bottom: 47%;
}

.img-comp-img {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.img-comp-img img {
    display: block;
    vertical-align: middle;
}

.img-comp-overlay {
    width: 50%;
    border-right: solid black 4px;
    height: 100%;
}

.img-comp-slider {
    position: absolute;
    z-index: 9;
    cursor: ew-resize;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}


.contain {
  object-fit: contain;
}

 ul.table-bullets {
    margin-bottom: 0rem;
 }

:target::before {
    content: '';
    display: block;
    height:      130px;
    margin-top: -130px;
}

/* change border radius for the tab , apply corners on top*/

#releaseNotesTab .nav-pills > li > a {
  color: #1eb1e7;
}



#releaseNotesTab li.active > a:nth-child(1) {
  dispalay: none;
}

.scroll-posts {
  height: calc(100vh );
  overflow-y: auto;
}


#toc .sublist {
    margin-left: 20px;
    display: none; /* Initially hide all sublists */
}

#toc .sublist .toc-link {
    display: block; /* Ensure links are displayed correctly within sublists */
}

.toc-link.active {
    font-weight: bold; /* Highlight the active section */
}