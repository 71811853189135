
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
$animate: all 0.3s ease-in-out;

.header {
  position: fixed;
  z-index: 100;
  background-color: #fff;
}

.megaMenu-topNav-btn-link {
  position: relative;
}
.megaMenu-topNav-btn-link a {
  background: linear-gradient(currentColor, currentColor) bottom / 0 .2em no-repeat;
  padding-bottom: .4em;
  border-radius: 4px;
  color: $brand-primary;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
    &::after {
      content: "+";
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      size: 17px;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;  
    }
  &::before {
    content: '';
    background: #1eb1e7;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.2s ease-in-out;
  }
  }

.megaMenu-topNav-btn-link a:hover {
  border-radius: 2px;
  color:#1eb1e7;
    &::after {
      content: "-";
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      size: 17px;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;       
    }
  &::before {
    width: calc(100% + 5px);
  }
  }

.megaMenu-topNav-btn-link--active a {
  border-radius: 2px;
  color:#1eb1e7;
  &::after {
    content: "-";
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    size: 17px;
    display: inline-block;
    padding-left: 4px;
    vertical-align: center;
    position: absolute;
  }
  &::before {
    width: calc(100% + 5px);
  }
}

.megaMenu-topNav-btn-link {
  background-color: transparent;
  border:none;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  color: $brand-primary;
  font-family: Roboto,arial,sans-serif;
  text-decoration: none;
}

#megaMenu-topNav-hover {
  height: 1px;
  background-color: $brand-primary;
  width: 50px;
  text-align: center;
  padding-top: 2px;
}

.sub-link {
  position: relative;
}
.sub-link a {
  font-size: 20px;
  transition: width 0.4s ease-in-out;

  &::before {
    content: '';
    background: #fff;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.2s ease-in-out;
  }
}

.sub-link a:hover {
  border-radius: 2px;
  color: #fff;
  &::before {
    width: calc(100% - 15px);
  }
}




.megaMenu-panel-btn-wt {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid white;
  padding: 10px;
  color: white;
  margin-bottom: 10px;
  display: inline-flex;
  font-size: 14px;
}

.megaMenu-panel-btn-wt a {
  font-size: 32px !important;
  font-weight: 400 !important;
}

.megaMenu-panel-btn-wt a:hover {
  color: white !important;
}

.megaMenu-panel-btn-wt {
    color: #fff;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid white;
    padding: 10px;
    color: white;
    &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-right: 14px;
      vertical-align: middle;
      -webkit-transition: transform 0.1s ease-out;
      -moz-transition: transform 0.1s ease-out;
      -ms-transition: transform 0.1s ease-out;
      -o-transition: transform 0.1s ease-out;
      transition: transform 0.1s ease-out;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}

.megaMenu-panel-btn-wt > span:nth-child(2) {
  padding-left:16px;
}

.megaMenu-panel-btn-blk {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid #000;
  padding: 10px;
  color: #000;
  margin-bottom: 10px;
  display: inline-flex;
}

.megaMenu-panel-btn-blk a {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.megaMenu-panel-btn-blk a:hover {
  color: #000 !important;
}

.megaMenu-panel-btn-blk {
    color: #000;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid #000;
    padding: 10px;
    color: #000;
    &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-right: 14px;
      vertical-align: middle;
      -webkit-transition: transform 0.1s ease-out;
      -moz-transition: transform 0.1s ease-out;
      -ms-transition: transform 0.1s ease-out;
      -o-transition: transform 0.1s ease-out;
      transition: transform 0.1s ease-out;
    }

    &:hover {
        color: #000;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
      }
    }

.megaMenu-panel-btn-blk > span:nth-child(2) {
  padding-left:16px;
}





/* topNav */



.topNav-pannel-btn-link {
  background-color: transparent;
  border-radius: 2px;
  border: none;
  padding: 10px;
  color: white;
  font-size: 18px;
}

.topNav-pannel-btn-link a:hover {
  color: #fff !important;
}



.topNav-pannel-btn-wt {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid white;
  padding: 10px;
  color: white;
  margin-bottom: 10px;
}

.topNav-pannel-btn-wt a:hover {
  color: white !important;
}

.topNav-pannel-btn-wt {
    color: #fff;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid white;
    padding: 10px;
    color: white;
    &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      display: inline-block;
      padding-right: 14px;

      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}


.sideNav-btn-link {
    background-color: #fff;
    border:none;
    display: inline-block;
    color: $brand-primary;
    text-decoration: none;
    width: 100%;
    text-align: left !important;
    margin-top: 30px;
}

.side-btn-link a {
    text-align: left !important;
    text-decoration: none;
}

.side-btn-link a:hover {
    color: $brand-primary;
    text-decoration: none;
}

.subNav-btn-link {
    background-color: $brand-primary;
    border:none;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    width: 100%;
    text-align: left !important;
    margin-top: 30px;
}

.subNav-btn-link a {
    text-align: left !important;
    text-decoration: none;
}

.subNav-btn-link a:hover {
    color: #fff;
    text-decoration: none;
}





.smallNav-overlay-btn-wt {
    color: #fff;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid white;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
    display: inline-flex; 
    vertical-align: middle;   
    &::after {
      content: "\f061";
        padding-right:14px;
        padding-top:10px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}


.button:hover {
    color: $brand-primary;
    text-decoration: none;
}

.topNav-pannel-btn-blk {
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid #000;
  padding: 10px;
  color: #000;
  margin-top: 18px;
}

.topNav-pannel-btn-blk a {
  color: #000 !important;
}

.topNav-pannel-btn-blk a:hover {
  color: #000 !important;
}

.topNav-pannel-btn-blk {
    color: #000;
    &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      display: inline-block;
      padding-right: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
        color: #000;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}


.megaMenu-lang-btn-col {
  text-align: left;
}


#close-btn {
  border:none;
  background-color:transparent
}




.link-btn-link {
    background-color: #fff;
    display: inline-flex;
    font-weight: 900;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1.4px;

    border: none;
    color: $brand-primary;
    text-decoration: none;
    width: 100%;
    text-align: left !important;
    margin-top: 30px;
    float: left;
}


.link-btn-link a{
  display: inline;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  color:$brand-primary;
  font-family: Roboto,arial,sans-serif;
  text-decoration: none;
  
}

.link-btn-link a:hover {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: $brand-primary;
}



.link-btn-caret {
text-align: right;
}

#link-btn-underline {
    width: 100%;
}

.link-btn-underline {
    display: inline-flex;
}

#link-btn-underline {
  .link-btn-underline {
    display: inline;
    flex-flow: row nowrap;
    align-items: left;

   
    .hover {
      margin: 0 auto;
      padding: 0;
      transition: $animate;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 0px;
        height: 2px;
        margin: 10px 0 0;
        transition: $animate;
        transition-duration: 0.4s;
        opacity: 0;
        background-color: $brand-primary;
      }

      &.link-btn-underline-hover {
         &:before,
        &:after {
          left: 0;
        }
     }
    }
    &:hover {
      cursor: pointer;
      .hover {
        &:before,
        &:after {
          width: 24px;
          opacity: 1;
           
        }
        &.link-btn-underline-hover,
        {
          &:before,
          &:after {
            width: 20px;
            background-color: $brand-primary;
          }
        }
      }
    }
  }
}





a.post-btn-link-active {
  color: #fff !important;
  text-decoration: none;
  background: $brand-primary;
  padding: 1.0em;
  border-radius: 4px;
  display: inline-block;
  transition: all 0.4s ease 0s;
  border:none;
  margin-bottom: 1em;
  font-size: 14px;
  width: 80%;
}

a.post-btn-link-active:hover {
  color: #fff !important;
  text-decoration: none;
}

a.post-btn-link {
  color:#555;
  padding: 1.0em;
  margin-bottom: 1em;
  display: inline-block;
  font-size: 14px;
 width: 80%; 
}

a.post-btn-link:hover {
  color: #fff !important;
  text-decoration: none;
  background: $brand-primary;
  padding: 1.0em;
  border-radius: 4px;
  transition: all 0.4s ease 0s;
  border:none;
  margin-bottom: 1em;
}

a.next-post-btn-link-active {
  color: $brand-primary;
  text-decoration: none;
  padding: .4em;
  font-weight: 900;
  display: inline-block;
  transition: all 0.4s ease 0s;
  margin-bottom: 1em;
  font-size: 14px;
  width: 80%;
}

a.next-post-btn-link-active:hover {
  color: $brand-secondary;
  text-decoration: none;
}

a.next-post-btn-link {
  color:#555;
  padding: .4em;
  font-weight: 400;
  margin-bottom: 1em;
  display: inline-block;
  font-size: 14px;
 width: 80%; 
}

a.next-post-btn-link:hover {
  color: $brand-primary;
  text-decoration: none;
  font-weight: 600;
  padding: .4 em;
  border-radius: 4px;
  transition: all 0.4s ease 0s;
  border:none;
  margin-bottom: 1em;
}

a.next-post-link {
  color: $brand-primary;
  &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      color: $brand-primary;
    }

      &:hover {
        color: $brand-primary;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}

button.next-post-link-wt {
  color: $brand-white;
  &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      color: $brand-white;
    }

      &:hover {
        color: $brand-white;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}

a.previous-post-link {
  color: $brand-primary;
  &::before {
      content: "\f060";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-right: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      color: $brand-primary;
    }

      &:hover {
        color: $brand-primary;
        text-decoration: none;
       
        &::before {
            -webkit-transform: translateX(-4px);
            -moz-transform: translateX(-4px);
            -ms-transform: translateX(-4px);
            -o-transform: translateX(-4px);
            transform: translateX(-4px);
        }
    }
}

#nav-search {
  padding: 10px;
  background-color: #f0f0f1;
  transition: .3s transform ease-in-out;
}

a#toggle-btn.nav-search{
  color: #000 !important;
}

div.col-md-1:nth-child(5){
  text-align: right !important;
  padding-right: 32px;
}

/* lang buttons */
/*smallMenu lang button */
.lang-btn-a {
appearance: none;
border: solid 1px $brand-primary;
color: $brand-primary;
background: transparent;
display: inline-block;
font-size: 14px;
position: relative;
outline: none;
cursor: pointer;
line-height: 1.5;
z-index: 5;
border-radius: 2px;
padding: 4px 10px;
min-width: 50px
}

.dropdown-menu-a {
  display:none;
  color: #000;
}

.dropdown-menu-a.dropdown-menu-a-open {
  background-color: white;
  display: flex;
  position:fixed;
  width: 108px;
  height: 54px;
  padding: 16px 10px;
  margin-top: 1px;
  border-radius: 0px 4px 4px 2px;
  border: solid 1px rgba(119,119,119,0.57);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  font-size: 14px;
  color: #000;
  -webkit-box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  -moz-box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  z-index: 999999;
}

.dropdown-menu-a.dropdown-menu-a-open a {
  color: rgba(000,000,000,0.85);
font-weight: 500;
  text-decoration: none;
    cursor: pointer;
}

.dropdown-menu-a.dropdown-menu-a-open a:hover {
color: $brand-primary;
}
.dropdown-menu-a.dropdown-menu-a-open {
   &::after {
        content: "\f061";
        color: $brand-primary;
        font-family: 'Font Awesome 5 Free';
        font-weight: 500;
        display: inline-block;
        padding-right: 14px;
        padding-left: 4px;
        vertical-align: middle;
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
      }

      &:hover {
          color: $brand-primary;
          text-decoration: none;
         
          &::after {
              -webkit-transform: translateX(4px);
              -moz-transform: translateX(4px);
              -ms-transform: translateX(4px);
              -o-transform: translateX(4px);
              transform: translateX(4px);
          }
      }
  }



/*megaMenu lang button */
.lang-btn-b {
appearance: none;
border: solid 1px $brand-primary;
color: $brand-primary;
background: transparent;
display: inline-block;
font-size: 14px;
position: relative;
outline: none;
cursor: pointer;
line-height: 1.5;
z-index: 5;
border-radius: 2px;
padding: 4px 10px;
min-width: 50px
}

.dropdown-menu-b {
  display:none;
  color: #000;
}

.dropdown-menu-b.dropdown-menu-b-open {
  background-color: white;
  display: flex;
  position:fixed;
  width: 108px;
  height: 54px;
  padding: 16px 10px;
  margin-top: 1px;
  border-radius: 0px 4px 4px 2px;
  border: solid 1px rgba(119,119,119,0.57);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  font-size: 14px;
  color: #000;
  -webkit-box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  -moz-box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  box-shadow: 10px 10px 15px -17px rgba(119,119,7,0.57);
  z-index: 999999;
}

.dropdown-menu-b.dropdown-menu-b-open a {
  color: rgba(000,000,000,0.85);
font-weight: 500;
  text-decoration: none;
    cursor: pointer;
}

.dropdown-menu-b.dropdown-menu-b-open a:hover {
color: $brand-primary;
}
.dropdown-menu-b.dropdown-menu-b-open {
   &::after {
        content: "\f061";
        color: $brand-primary;
        font-family: 'Font Awesome 5 Free';
        font-weight: 500;
        display: inline-block;
        padding-right: 14px;
        padding-left: 4px;
        vertical-align: middle;
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
      }

      &:hover {
          color: $brand-primary;
          text-decoration: none;
         
          &::after {
              -webkit-transform: translateX(4px);
              -moz-transform: translateX(4px);
              -ms-transform: translateX(4px);
              -o-transform: translateX(4px);
              transform: translateX(4px);
          }
      }
  }

.sitemap-btn-link {
  font-weight: 400;
  border: none;
  color: $brand-primary;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  margin-bottom: -10px;
  border: none;
  cursor: pointer;
}

.sitemap-btn-link  {
  position: relative;
  color: $brand-primary;
}

.sitemap-btn-link a  {
  color: $brand-primary;
}

.sitemap-btn-link:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 8px;
  background-color: $brand-primary;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.sitemap-btn-link:hover:before {
  visibility: visible;
  width: 30px;
}

.sitemap-kb-category-title {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
}

#sitemap-expand-button {
  color: $brand-primary;
}

#kb-articles-expand-link {
  color: rgba(0,0,0,0.85);
  text-decoration: none;
  cursor: pointer;
}

#next-post-article-list {
  padding-left: inherit;
}


.btn-tab {
background-color: rgba(0,0,0,.05);
border-radius: 4px 4px 0 0;
border: solid 1px rgba(0,0,0,.15);
padding: 4px 4px 4px 4px;
color: rgba(0,0,0,.65);
}


.kb-panel-btn-blk {
  display: inline-block;
  position: relative;
  padding-right: 30px;
  border-radius: 2px;
  padding: 8px 50px 8px 15px;
  font-weight: 400;
  border: 2px solid rgba(250,250,250,0.65);
  color: white;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  background-color: rgba(000,000,000,0.15);
  margin-top: 20px;
  
  
}

.kb-panel-btn-blk:hover::after {
  right: 15px;
}

.kb-panel-btn-blk:after {
    position: absolute;
    content: "\f061";
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    line-height: 0;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: currentColor;
    -webkit-transition: right .25s ease-out;
    transition: right .25s ease-out;
}

.kb-panel-btn-blk a {
  color: white;
  text-decoration: none;
}

.kb-panel-btn-blk a:hover {
  color: white;
  text-decoration: none;
}

.previous-post a {
  color: $brand-primary;
  text-decoration: none;
}

.previous-post>a:hover {
  color: $brand-primary;
  text-decoration: none;  
}

.next-post a {
  color: $brand-primary;
  text-decoration: none;
}

.next-post>a:hover {
  color: $brand-primary;
  text-decoration: none;  
}

button.next-post-link {
  color: $brand-primary;

  &::after {
      content: "\f061";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      color: $brand-primary;
    }

    &:hover {
        color: $brand-primary;
        text-decoration: none;
       
        &::after {
            -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
        }
    }
}

button.previous-post {
  &::before {
      content: "\f060";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      padding-left: 14px;
      padding-right: 4px;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      color: $brand-primary;
    }

    &:hover {
        color: black;
        text-decoration: none;
       
        &::before {
            -webkit-transform: translateX(-4px);
            -moz-transform: translateX(-4px);
            -ms-transform: translateX(-4px);
            -o-transform: translateX(-4px);
            transform: translateX(-4px);
        }
    }
}

a.link-underline-right {

    &::after {
      content: "\f106";
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
        &::after {
            -webkit-transform: translateY(-4px);
            -moz-transform: translateY(-4px);
            -ms-transform: translateY(-4px);
            -o-transform: translateY(-4px);
            transform: translateY(-4px);
        }
    }

}


a.link-underline-right   {
  background: linear-gradient(currentColor, currentColor) bottom / 2 .1em no-repeat;
  transition: .5s background-size;
  padding-bottom: .2em;
  border-radius: 1px;
}

a.link-underline-right:hover {
  background-size: 35% .1em;
  border-radius: 1px;
}



a.page-underline-link:hover {
  opacity: .90;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}  


a.page-underline-link  {
  text-decoration: none;
  color: rgba(000,000,000,0.9);
  padding-bottom: .2em;
} 

a.page-underline-link {
  position: relative;
  color: currentColor;
  text-decoration: none;
  background-image: linear-gradient(currentColor,currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  word-break: none;
  transition: opacity .25s ease-out,background-size .25s ease-out;
}

/* Copy Code to clipboard button */
.copy-code-container {
  display: flex;
  justify-content: flex-end;

}


button.copy-code-button { 
  color: $brand-primary;
}

button.copy-code-button:hover { 
  color: $brand-primary;
  opacity: 65%
}



button.copy-code-button {


    &::before {
        content: "Copy";
    }

    &::after {
        margin-left: 4px;
        content: "\f0c5";
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        width: 1em;
    }

    // This class will be toggled via JavaScript
    &.copied {
      opacity: 100%;
        &::before {
            content: "Copied";
        }

        &::after {
        content: "\f00c";
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        }
    }
}

.post-linkLike-link {
  border-bottom: solid 1px rgba(000,000,000,0.85);
  padding-bottom: 1px;
  font-size: 12px;
  font-weight: 500;
}


.post-article-link a{
  position: relative;
  color: rgba(000,000,000,0.95);
  font-size: 12px;
  text-decoration: none;
  background-image: linear-gradient(currentColor,currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 2px;
  word-break: none;
  transition: opacity .25s ease-out,background-size .25s ease-out;
}

.post-article-link a:hover {
  opacity: .85;
  background-size: 0 1px;
  text-decoration: none !important;
  color: currentColor;
}

.post-button-brand-brand-complementary-5 {
  border-radius: 4px;
  background-color: #0072bc;
  color: white;
  padding: 6px 10px 6px 10px;
}



.accordion button.btn.btn-link.btn-block.text-left {
  position: relative;
}

.accordion button.btn.btn-link.btn-block.text-left  {
  background: linear-gradient(currentColor, currentColor) bottom / 0 .2em no-repeat;
  padding-bottom: .4em;
  border-radius: 4px;
  color: $brand-primary;
  font-weight: 300;
  font-size: 21px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
    &::after {
      content: "\f107";
      font-family: 'Font Awesome 5 Free';
      font-weight: 500;
      width: 1em;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;  
    }
  &::before {
    content: '';
    background: #1eb1e7;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.2s ease-in-out;
  }
  }

.accordion button.btn.btn-link.btn-block.text-left:hover {
  border-radius: 2px;
  color:#1eb1e7;
  text-decoration: none;
    &::after {
      content: "\f107";
      font-family: 'Font Awesome 5 Free';
      font-weight: 500;
      width: 1em;
      display: inline-block;
      padding-left: 4px;
      vertical-align: center;
      position: absolute;       
    }
  &::before {
    width: calc(100% + 5px);
  }
  }

.accordion button.btn.btn-link.btn-block.text-left:focus  {
  border-radius: 2px;
  color:#1eb1e7;
  box-shadow: none;
  &::after {
    content: "\f106";
    font-family: 'Font Awesome 5 Free';
    font-weight: 500;
    width: 1em;
    display: inline-block;
    padding-left: 4px;
    vertical-align: center;
    position: absolute;
  }
  &::before {
    width: calc(100% + 5px);
  }
}

.accordion button.btn.btn-link.btn-block.text-left:focus-visible {
  border-radius: 2px;
  color:#1eb1e7;
  box-shadow: none;
  &::after {
    content: "\f106";
    font-family: 'Font Awesome 5 Free';
    font-weight: 500;
    width: 1em;
    display: inline-block;
    padding-left: 4px;
    vertical-align: center;
    position: absolute;
  }
  &::before {
    width: calc(100% + 5px);
  }
}


.accordion button.btn.btn-link.btn-block.text-left {
  background-color: transparent;
  border:none;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1.4px;
  color: $brand-primary;
  font-family: Roboto,arial,sans-serif;
  text-decoration: none;
}
